.custom-material-table {
	height: 100%;
	overflow-y: hidden;
}
.custom-material-table .MuiPaper-root {
	height: 100%;
	overflow-y: hidden;
}
.custom-material-table div[class*="Component-horizontalScrollContainer"] {
	height: calc(100% - 125px);
	overflow-y: auto;
}
.custom-material-table div[class*="Component-horizontalScrollContainer"] div {
	max-height: unset !important;
	height: 100%;
}
.custom-material-table-100 {
	height: 100%;
	overflow-y: hidden;
}
.custom-material-table-100 .MuiPaper-root {
	height: 100%;
	overflow-y: hidden;
}
.custom-material-table-100 div {
	max-height: unset !important;
}
.custom-material-table-100 div[class*="Component-horizontalScrollContainer"], .custom-material-table-100 div[class^="jss"] {
	height: calc(100% - 100px);
	overflow-y: auto;
}
.custom-material-table-100 div[class*="Component-horizontalScrollContainer"] div, .custom-material-table-100 div[class^="jss"] div {
	max-height: unset !important;
	height: 100%;
}
.custom-material-table-100 div[class*="Component-horizontalScrollContainer"] .MuiTableCell-alignLeft, .custom-material-table-100 div[class^="jss"] .MuiTableCell-alignLeft {
	overflow: hidden;
	overflow-wrap: break-word;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.custom-material-table-100 div[class*="Component-horizontalScrollContainer"] .MuiInputBase-root, .custom-material-table-100 div[class^="jss"] .MuiInputBase-root {
	align-items: unset;
}
.custom-editable-material-table-100 {
	height: 100%;
	overflow-y: hidden;
}
.custom-editable-material-table-100 .MuiPaper-root {
	height: 100%;
	overflow-y: hidden;
}
.custom-editable-material-table-100 div {
	max-height: unset !important;
}

.custom-editable-material-table-100 div[class*="MuiOutlinedInput-multiline"] {
	height: unset !important;
}


.custom-editable-material-table-100 div[class*="Component-horizontalScrollContainer"], .custom-editable-material-table-100 div[class^="jss"] {
	height: calc(100% - 100px);
	overflow-y: auto;
}
.custom-editable-material-table-100 div[class*="Component-horizontalScrollContainer"] div, .custom-editable-material-table-100 div[class^="jss"] div {
	max-height: unset !important;
	height: 100%;
}
.custom-editable-material-table-100 div[class*="Component-horizontalScrollContainer"] .MuiTableCell-alignLeft, .custom-editable-material-table-100 div[class^="jss"] .MuiTableCell-alignLeft {
	max-height: 150px;
	max-width: 300px;
	overflow: hidden;
	overflow-wrap: break-word;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.custom-editable-material-table-100 div[class*="Component-horizontalScrollContainer"] .MuiInputBase-root, .custom-editable-material-table-100 div[class^="jss"] .MuiInputBase-root {
	height: 150px;
	align-items: unset;
} */


.custom-editable-material-table-100 div[class*="Component-horizontalScrollContainer"] .MuiInputBase-input, .custom-editable-material-table-100 div[class^="jss"] .MuiInputBase-input {
	width: unset;
}

.custom-property-material-table-100 {
	height: calc(100% - 40px);
	overflow-y: hidden;
}

.custom-property-material-table-100 .MuiPaper-root {
	height: 100%;
	overflow-y: hidden;
}

.custom-property-material-table-100 div::before {
	visibility: hidden;
}
.custom-property-material-table-100 div:not(:focus-within)::before {
	visibility: visible;
}
.custom-property-material-table-100 div::after{
	visibility: hidden;
} 
.custom-property-material-table-100 div[class*="Component-horizontalScrollContainer"] {
	height: calc(100% - 100px);
	overflow-y: auto;
}
.custom-property-material-table-100 div[class*="Component-horizontalScrollContainer"] div {
	max-height: unset !important;
	height: 100%;
}
.custom-property-material-table-100 div[class*="Component-horizontalScrollContainer"] .MuiTableCell-alignLeft {
	overflow: hidden;
	overflow-wrap: break-word;
	white-space: nowrap;
	text-overflow: ellipsis;
}



.custom-property-material-table-100 div[class*="Component-horizontalScrollContainer"] .MuiInputBase-root {
	height: 150px;
	align-items: unset;
}
.custom-property-material-table-100 div[class*="Component-horizontalScrollContainer"] .MuiInputBase-input {
	width: unset;
}





.custom-editable-material-table-165 {
	height: 100%;
	overflow-y: hidden;
}
.custom-editable-material-table-165 .MuiPaper-root {
	height: 100%;
	overflow-y: hidden;
}

.custom-editable-material-table-165 div::before {
	visibility: hidden;
}
.custom-editable-material-table-165 div:not(:focus-within)::before {
	visibility: visible;
}
.custom-editable-material-table-165 div::after{
	visibility: hidden;
} 

.custom-editable-material-table-165 div[class*="Component-horizontalScrollContainer"] {
	height: calc(100% - 165px);
	overflow-y: auto;
}
.custom-editable-material-table-165 div[class*="Component-horizontalScrollContainer"] div {
	max-height: unset !important;
	height: 100%;
}
.custom-editable-material-table-165 div[class*="Component-horizontalScrollContainer"] .MuiTableCell-alignLeft {
	max-height: 150px;
	max-width: 300px;
	overflow: hidden;
	overflow-wrap: break-word;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.custom-editable-material-table-165 div[class*="Component-horizontalScrollContainer"] .MuiInputBase-root {
	height: 150px;
	align-items: unset;
}
.custom-editable-material-table-165 div[class*="Component-horizontalScrollContainer"] .MuiInputBase-input {
	width: unset;
}




.modal-table {
	height: calc(100% - 20px);
	overflow-y: hidden !important;
}
.modal-table div[class*="Component-horizontalScrollContainer"] {
	height: calc(100% - 100px);
}
.modal-table .modal-dialog {
	height: inherit !important;
	overflow-y: hidden;
}
.modal-table .modal-content {
	height: inherit !important;
	overflow-y: hidden;
}
.custom-min-material-table {
	height: 100%;
	min-width: 345px;
}
.custom-min-material-table .MuiPaper-root {
	height: 100%;
	overflow-y: hidden;
	background: transparent;
}
.custom-min-material-table div {
	background-color: unset !important;
}
.custom-min-material-table div[class*="Component-horizontalScrollContainer"],
.custom-min-material-table div[class*="jss"]{
	height: calc(100% - 130px);
	overflow-y: visible;
}

.custom-min-material-table div[class*="Component-horizontalScrollContainer"] div, 
.custom-min-material-table div[class^="jss"] div {
	max-height: unset !important;
	height: 100%;
}
.custom-min-material-table div[class*="Component-horizontalScrollContainer"] thead,
.custom-min-material-table  div[class^="jss"] thead {
	background: rgba(34, 34, 34, 0.94);
}
.custom-min-material-table div[class*="Component-horizontalScrollContainer"]thead th, 
.custom-min-material-table div[class^="jss"] thead th {
	background: rgba(34, 34, 34, 0.94);
	color: #f3f7fb;
}
.custom-min-material-table div[class*="Component-horizontalScrollContainer"] td,
.custom-min-material-table div[class^="jss"] td, 
.custom-min-material-table div[class*="Component-horizontalScrollContainer"] th, 
.custom-min-material-table div[class^="jss"] td {
	border-bottom: unset;
	height: 10px;
	padding: 0px 5px;
	background: transparent;
}
.custom-min-material-table div[class*="Component-horizontalScrollContainer"] .MuiTableCell-alignLeft {
	overflow: hidden;
}
