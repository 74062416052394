.spinner {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 10%;

	.animation {
		width: 75px;
		height: 75px;
	}

	.text {
		margin: 30px 20px;
		word-wrap: break-word;
		-webkit-text-stroke-width: medium;
	}
}
