.aimspainter-toolbar {
	top: 40%;
	width: 32px !important;
	left: '0%' !important;
	z-index: '100' !important;
	position: 'absolute' !important;
	text-align: left !important;
}

.react-docking-panel {
	resize: auto !important;	
}
/* .forge-panel-bckg {
	background-color: #222;
   } */
.asset-list-panel {
	height: 560px;
	width: 340px;
	left: 70px;
	top: 10px;
}

.asset-structure-panel {
	height: 560px;
	width: 340px;
	left: 70px;
	top: 10px;
}

.viewer-panel {
	height: 400px;
	width: 280px;
	right: 10px;
	top: 10px;

		text-align: center;
	
}

.editor-panel {
	height: 380px;
	width: 400px;
	right: 10px;
	top: 10px;
}

.asset-list-icon-btn {
	-webkit-mask-box-image: url('../../../core/icons/list_512.png');
	background-color: white;
}

.asset-paint-icon-btn {
	-webkit-mask-box-image: url('../../../core/icons/paint-brush_512.png');
	background-color: white;
}

.asset-view-icon-btn {
	-webkit-mask-box-image: url('../../../core/icons/view_256.png');
	background-color: white;
}

.select-box-icon-btn {
	-webkit-mask-box-image: url('../../../core/icons/dotted-square.png');
	background-color: white;
}

.active .asset-list-icon-btn,
.active .asset-paint-icon-btn,
.active .select-box-icon-btn,
.active .asset-view-icon-btn {
	background-color: #00bfff;
}
