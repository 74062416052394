body {
    font-family: 'Roboto', sans-serif;
    height: 100vh;
    /* color: #2C3E4E !important; */
    margin: 0;
    padding: 0;
}

#root {
    height: 100%;
}

.app {
    background-color: #F5F5F5 !important;
    width: 100%;
    height: calc(100% - 0px);
    overflow: hidden;
}

body::-webkit-scrollbar {
    display: none;
}

::-webkit-scrollbar {
    width: 14px;
    height: 6px;
}

::-webkit-scrollbar-track {
    background-clip: padding-box;
    border-left: 8px solid rgba(0, 0, 0, 0);
    background-color: var(--v-lightgrey-base);
}

::-webkit-scrollbar-corner {
    background-color: var(--v-lightgrey-base);
}

::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border-left: 8px solid rgba(0, 0, 0, 0);
    background-color: #9E9E9E;
}

a:-webkit-any-link {
    color: inherit;
    cursor: pointer;
    text-decoration: none;
}


.MuiListItem-root {
    padding: 5px 0;
}


.MuiListItemIcon-root {
    min-width: 35px;
    margin-left: 15px;
}

.MuiIcon-root {
    width: auto;
}

.MuiIcon-fontSizeSmall {
    font-size: 1em;
}

.white {
    color: white;
}

.grey {
    color: #E3E4E6;
}

.fa-fw {
    width: 1.25em;
}

.MuiDataGrid-main div[style*="z-index: 100000"] {
    visibility: hidden;
}

.noselect {

    -webkit-touch-callout: none;
    /* iOS Safari */

    -webkit-user-select: none;
    /* Safari */

    -khtml-user-select: none;
    /* Konqueror HTML */

    -moz-user-select: none;
    /* Old versions of Firefox */

    -ms-user-select: none;
    /* Internet Explorer/Edge */

    user-select: auto;
    /* Non-prefixed version, currently */

}