.rdg-filter-container {
    display: flex;
    height: inherit;
    align-items: center;
  }
  
  .rdg-filter {
    width: 100%;
    height: 30px;
    padding: 4px;
    font-size: 14px;
  }


  .filter-cell {
    line-height: 35px;
    height: 70px;
    padding: 0;
  }

   .filter-cell div {
      padding: 0 8px;
   }
   .filter-cell div first-child {
        border-bottom: 1px solid var(--border-color);
      }
