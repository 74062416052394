.page-not-found {

    height: 100%;

    .container {
        background: rgba(255, 255, 255, 0.75);
        border-radius: 6px;
        position: relative;
        max-width: 800px;
        font-size: 15px;
        padding: 36px;
        margin: auto;
        width: 95%;
        top: 30%;
    }

}