.btn-dropdown {
	width: 100%;
}
.viewer-grid {
	margin: 0px;
	height: 90%;
}
.viewer-page {
	background-color: #f8f9fa !important;
	height: calc(100% - 56px) !important;
}
.splitter-page {
	position: unset !important;
}
.forge-viewer {
	height: inherit;
}
.overflow-scroll {
	overflow-y: scroll;
}
.hidden {
	display: none;
}
.paint-btn-active {
	background-color: grey;
	border-color: grey;
}
.paint-btn-active:hover {
	background-color: grey;
	border-color: grey;
}
.inline {
	display: inline-flex;
}
.layout-pane {
	height: 100%;
}
