.model-gallery {
	justify-content: center;
}
.model-gallery ul {
	display: inline-flex;
}
.model-grid {
	width: calc(100% - 50px);
	margin: 25px;
}
.grid-tile div[class*="MuiGridListTileBar-root"] {
	background: linear-gradient(to right, darkgrey 50%, grey 50%);
	transition: all 0.3s ease-out;
	background-size: 200% 100%;
	background-position: right bottom;
}
.grid-tile button {
	background-color: transparent;
}
.grid-tile :hover div[class*="MuiGridListTileBar-root"] {
	background-position: left bottom;
}
.grid-tile :hover button {
	background-color: transparent;
}
.non-fav-icon {
	color: white;
	outline: 0px;
}
.fav-icon {
	color: yellow;
	outline: 0px;
}
